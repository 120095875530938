import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Scrollbar } from '../../components/scrollbar';
import { SeverityPill } from '../../components/severity-pill';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export const BusinessesTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    handleFilters
  } = props;

  const navigate = useNavigate();

  const [filterOptions, setFilterOptions] = useState({
    active: false,
    inactive: false,
  });

  const { 
    active,
    inactive,
  } = filterOptions;

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      [name]: checked
    }));
  };

  useEffect(() => {
    handleFilters(filterOptions);
    // eslint-disable-next-line
  }, [filterOptions]);

  return (
    <Card>
      <FormControl sx={{ m: 1 }}
component="fieldset"
variant="standard">
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Checkbox checked={active} 
                onChange={handleChange} 
                name="active" 
              />
            }
            label="Activa"
          />
          <FormControlLabel
            control={
              <Checkbox checked={inactive} 
                onChange={handleChange} 
                name="inactive" 
              />
            }
            label="Inactiva"
          />
        </FormGroup>
      </FormControl>

      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Empresa
                </TableCell>
                <TableCell>
                  Descripción
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Creada
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((business) => {
                const createdAt = format(new Date(business.createdAt), 'dd/MM/yyyy');
                return (
                  <TableRow
                    hover
                    key={business.id}
                  >
                    <TableCell onClick={()=>navigate(`/businesses/business/${business.id}`)}>
                      {business.name}
                    </TableCell>
                    <TableCell>
                      {business.description}
                    </TableCell>
                    <TableCell>
                      <SeverityPill color={business.status ? 'success' : 'error'}>
                        {business.status ? 'Activo' : 'Inactivo'}
                      </SeverityPill>
                    </TableCell>
                    <TableCell>
                      {createdAt}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

BusinessesTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};
