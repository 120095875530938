import { Route, Routes } from 'react-router-dom';
import LoginPage from '../auth/login';
import { AppRoutes } from '../app/AppRoutes';
import { PublicRoute } from './PublicRoute';

export const AppRouter = () => {
  return (
    <>
        <Routes>            
            <Route path='login' element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
              } 
            />

            <Route path='*' element={
              <AppRoutes />
            } 
            />
        </Routes>
    </>
  )
}
