import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  InputLabel,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { patchUserApi, postUserApi } from '../../api/users';
import { getAllBusinessesApi } from '../../api/businesses';

const roles = [
  {
    value: '',
    label: 'Vacío'
  },
  {
    value: 'ADMIN',
    label: 'Administrador'
  },
  {
    value: 'MANAGER',
    label: 'Gerente'
  },
  {
    value: 'OWNER',
    label: 'Dueño'
  },
];

export const NewUserForm = ({newUser = true, user, handleSuccess }) => {
  
  const [modified, setModified] = useState(false);  
  const [businesses, setBusinesses] = useState([]);

  const fetchBusinesses = async () => {
    try {
      const data = await getAllBusinessesApi();
      setBusinesses(data);      
    } catch (error) {
      console.error('Error fetching businesses:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      passwordVal: '',
      role: '',
      businessId: '',
      status: false,
      submit: null
    },

    validationSchema: Yup.object({
      name: Yup
        .string()
        .max(255)
        .required('Inserta Nombre'),
      email: Yup
          .string()
          .email('Necesita ser un correo electrónico válido')
          .required('Inserta Descripción'),
      password: Yup
        .string()
        .max(255)
        .min(8, 'Mínimo 8 cacrcteres')
        .required('Inserta Contraseña'),
      passwordVal: Yup
        .string()
        .max(255)
        .min(8, 'Mínimo 8 cacrcteres')
        .required('Inserta Contraseña de Validación'),
      role: Yup
        .string()
        .max(255)
        .required('Inserta Rol'),
      businessId: Yup
        .number()
        .positive()
        .integer(),
      status: Yup
        .boolean(),
    }),
    onSubmit: async (values, helpers) => {
      if (values.password !== values.passwordVal) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: 'Password Validation Failed' });
        helpers.setSubmitting(false);
      }
      const dataToPost = {...values}
      delete dataToPost.passwordVal; 
      newUser ? console.log('newUser', dataToPost) : console.log('modifiedUser', dataToPost);
      try {
        newUser ? await postUserApi(dataToPost) : await patchUserApi(user.id, dataToPost);
        helpers.resetForm();
        setModified(false);
        handleSuccess();      
      } catch (error) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },      
  });

  const handleChange = () => {
    setModified(true);
  } 

  useEffect(() => {
    if(!newUser){
      formik.setValues({
        name: user.name ? user.name : '',
        email: user.email ? user.email : '',
        password: '',
        passwordVal: '',
        role: user.role ? user.role : '',
        businessId: user.business?.length ? user.business[0].business.id : '',
        status: user.status ? user.status : false,
        submit: null
      }); 
    }
    // eslint-disable-next-line
  }, [user, newUser])

  useEffect(() => {
    fetchBusinesses();
  }, [])
  
  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader
          subheader={ newUser ? "Agrega un usuario nuevo" : "Modifica un Usuario"}
          title={ newUser ? "Nuevo Usuario" : "Usuario Existente"}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  error={!!(formik.touched.name && formik.errors.name)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.name}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Correo Electrónico"
                  name="email"
                  error={!!(formik.touched.email && formik.errors.email)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.email}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Contraseña"
                  name="password"
                  type='password'
                  error={!!(formik.touched.password && formik.errors.password)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.password}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Contraseña Validación"
                  name="passwordVal"
                  type='password'
                  error={!!(formik.touched.passwordVal && formik.errors.passwordVal)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.passwordVal}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
              <InputLabel>Rol del Usuario</InputLabel>
                <Select
                  fullWidth
                  label="Rol del Usuario"
                  name="role"
                  error={!!(formik.touched.role && formik.errors.role)}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.role}
                  >
                  {roles.map((role) => (
                    <MenuItem key={role.label}
value={role.value}>{role.label}</MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid
                xs={12}
                md={6}
                >
                <InputLabel>Negocio del Usuario</InputLabel>
                <Select
                  fullWidth
                  label="Negocio del Usuario"
                  name="businessId"
                  error={!!(formik.touched.businessId && formik.errors.businessId)}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.businessId}
                >
                  {businesses.map((business) => (
                    <MenuItem key={business.id}
value={business.id}>{business.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              { !newUser &&
                <Grid
                xs={12}
                md={6}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.status}
                        value={formik.values.status}
                        onChange={(e) => {formik.handleChange(e);handleChange();}}
                        name='status'
                        onBlur={formik.handleBlur}      
                    />
                    }
                    label="Activo"
                  />
                </Grid>
              }
              {formik.errors.submit && (
                <Typography
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </Typography>
              )}
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
        { newUser &&
            <Button variant="contained"
type="submit">
              Crear Usuario
            </Button>
          }
          { !newUser && modified &&
            <Button variant="contained"
type="submit">
              Modificar Usuario
            </Button>
          }
        </CardActions>
      </Card>
    </form>
  );
};
