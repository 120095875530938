import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/auth-context';

export const PrivateRoute = ({children}) => {    
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    return <Navigate to='/login' />;
  }

  return children;
};
