import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { NewBusinessForm } from '../components/forms/new-business-form';
import { NewBranchForm } from '../components/forms/new-branch-form';
import { NewUserForm } from '../components/forms/new-user-form';
import { LllmGroupsForm } from './forms/llm-groups-form';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  p: 4,
};

export default function FormModal({ open, handleClose, formType, handleSuccess, businessId }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {formType === 'groupsCollections' && <LllmGroupsForm handleSuccess={handleSuccess} />}
          {formType === 'business' && <NewBusinessForm handleSuccess={handleSuccess} />}
          {formType === 'branch' && <NewBranchForm handleSuccess={handleSuccess}
businessId={businessId} />}
          {formType === 'user' && <NewUserForm handleSuccess={handleSuccess}  />}        
        </Box>
      </Modal>
    </div>
  );
}
