import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../components/shared/Toast';
import { LllmGroupsForm } from '../components/forms/llm-groups-form';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { useState } from 'react';
import FormModal from '../components/formModal';

const GroupsCollections = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSuccess = () => {
    handleCloseModal();
    ToastSuccessSync("Procedimiento Exitoso");
  }

  return (
    <DashboardLayout>
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  Collecciones de Grupos
                </Typography>
              </Stack>
              <div>
                <Button
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Agregar Nueva Colección
                </Button>
              </div>
            </Stack>

            <LllmGroupsForm
              newCollection={false}
              handleSuccess={handleSuccess} />
          </Stack>
        </Container>
      </Box>
      <FormModal open={openModal}
        formType='groupsCollections'
        handleClose={handleCloseModal}
        handleSuccess={handleSuccess} 
      />
    </DashboardLayout>
  );
};

export default GroupsCollections;
