import { useCallback, useEffect, useMemo, useState } from 'react';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import { BusinessesTable } from '../../components/tables/businesses-table';
import { applyPagination } from '../../utils/apply-pagination';
import FormModal from '../../components/formModal';
import { getAllBusinessesApi } from '../../api/businesses';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../../components/shared/Toast';

const Businesses = () => {
  const usePagedBusinesses = (page, rowsPerPage) => {
    return useMemo(
      () => {
        return applyPagination(filteredBusinesses, page, rowsPerPage);
      },
      // eslint-disable-next-line
      [page, rowsPerPage, filteredBusinesses]
    );
  };

  const [businesses, setBusinesses] = useState([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState([])
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const pagedBusinesses = usePagedBusinesses(page, rowsPerPage);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  
  const handleSuccess = () => {
    handleCloseModal();
    ToastSuccessSync("Procedimiento Exitoso");
    setTriggerFetch(prevState => !prevState);
  }
  
  const handlePageChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  );

  const handleFilters = (filters) => {
    const filteredResult = businesses.filter(business => {
      if (filters.active && !business.status) return false;
      if (filters.inactive && business.status) return false;
      return true;
    });
    setFilteredBusinesses(filteredResult);
  };
      
  useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await getAllBusinessesApi();
            setBusinesses(data);      
            setFilteredBusinesses(data);      
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };
    fetchData();  
  }, [triggerFetch])
  
  return (
    <DashboardLayout>
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  Empresas
                </Typography>
              </Stack>
              <div>
                <Button
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Agregar Nueva Empresa
                </Button>
              </div>
            </Stack>
            <BusinessesTable
              count={businesses.length}
              items={pagedBusinesses}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              handleFilters={handleFilters}
            />
          </Stack>
        </Container>
      </Box>

      <FormModal open={openModal}
formType='business'
handleClose={handleCloseModal}
handleSuccess={handleSuccess} />
    </DashboardLayout>
  );
};

export default Businesses;
