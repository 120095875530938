import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { NewBranchForm } from '../../components/forms/new-branch-form';
import { useEffect, useState } from 'react';
import { deleteBranch, getBranchApi } from '../../api/branches';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../../components/shared/Toast';
import { BranchUserForm } from '../../components/forms/branch-user-form';
import { useNavigate, useParams } from 'react-router-dom';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import ConfirmationModal from '../../components/confirmationModal';

const Branch = () => {
  const { branchId } = useParams();
  const navigate = useNavigate();
  const [branch, setBranch] = useState({});
  const [triggerFetch, setTriggerFetch] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleOpenConfirmationModal = () => setConfirmationModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  const handleSuccess = () => {
    ToastSuccessSync("Procedimiento Exitoso");
    setTriggerFetch(prevState => !prevState);
  }

  const handleSuccessDelete = async() => {
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds));  
    }
    handleCloseConfirmationModal();
    try {
      await deleteBranch(branchId);
      ToastSuccessSync("Procedimiento Exitoso");
      sleep(1000).then(() => {
        navigate(`/businesses/business/${branch.businessId}`);
      });
    } catch (error) {
      console.error('Error deleting branch:', error);
    }
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBranchApi(branchId);
        setBranch(data);  
        console.log(data);    
      } catch (error) {
        console.error('Error fetching branch:', error);
      }
    };
    branchId && fetchData();  
  }, [triggerFetch, branchId])

  return (
    <DashboardLayout>
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  {branch.name}
                </Typography>
              </Stack>
              <div>
                <Button
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <TrashIcon />
                    </SvgIcon>
                  )}
                  sx={{ bgcolor: 'error.main', color: 'white' }}
                  variant="contained"
                  onClick={handleOpenConfirmationModal}
                >
                  Borrar Sucursal
                </Button>
              </div>
            </Stack>
            <NewBranchForm newBranch={false}
                businessId={branch.businessId}
                branch={branch}
                handleSuccess={handleSuccess} />
            <BranchUserForm branch={branch}
                handleSuccess={handleSuccess} />
          </Stack>
        </Container>
      </Box>
      <ConfirmationModal open={confirmationModalOpen}
        confirmationText={`¿Estás seguro que deseas borrar la sucursal ${branch.name}? Toda la información relacionada con esta sucursal será eliminada sin excepción.`}
        handleClose={handleCloseConfirmationModal}
        handleSuccess={handleSuccessDelete} />
    </DashboardLayout>
  );
};

export default Branch;
