import { Route, Routes, Navigate } from 'react-router-dom';
import Main from './Main';
// import Dashboard from './Dashboard';
// import Groups from './Groups';
// import Reports from './Reports';
// import Tables from './Tables';
// import NewReview from './NewReview';
// import BranchHydration from '../utils/BranchHydration';
// import { OwnerPanelRoutes } from './ownerPanel/OwnerPanelRoutes';
import { PrivateRoute } from '../router/PrivateRoute';
import GroupsCollections from './GroupsCollections';
import { UsersRoutes } from './users/UsersRoutes';
import { BusinessesRoutes } from './businesses/BusinessesRoutes';

export const AppRoutes = () => (
  <Routes>
    <Route path='main' element={
      <PrivateRoute>
        <Main />
      </PrivateRoute>
    } />
    <Route path='groupsCollections' element={
      <PrivateRoute>
        <GroupsCollections />
      </PrivateRoute>
    } />
    <Route path='users/*' element={
      <PrivateRoute>
        <UsersRoutes />
      </PrivateRoute>
    } />
    <Route path='businesses/*' element={
      <PrivateRoute>
        <BusinessesRoutes />
      </PrivateRoute>
    } />
    
    <Route path='/*' element={<Navigate to='main' />} />
  </Routes>
)
