import { useCallback, useEffect, useMemo, useState } from 'react';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import { applyPagination } from '../../utils/apply-pagination';
import FormModal from '../../components/formModal';
import { UsersTable } from '../../components/tables/users-table';
import { getAllUsersApi } from '../../api/users';
import { ToastSuccessSync } from '../../components/shared/Toast';

const Users = () => {
  const usePagedUsers = (page, rowsPerPage) => {
    return useMemo(
      () => {
        return applyPagination(filteredUsers, page, rowsPerPage);
      },
      // eslint-disable-next-line
      [page, rowsPerPage, filteredUsers]
    );
  };
  
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const pagedUsers = usePagedUsers(page, rowsPerPage);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSuccess = () => {
    handleCloseModal();
    ToastSuccessSync("Procedimiento Exitoso");
    setTriggerFetch(prevState => !prevState);
  }

  const handlePageChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  );
  
  const handleFilters = (filters) => {
    const filteredResult = users.filter(user => {
      if (filters.active && !user.status) return false;
      if (filters.inactive && user.status) return false;
      if (filters.admin && user.role !== 'ADMIN') return false;
      if (filters.manager && user.role !== 'MANAGER') return false;
      if (filters.owner && user.role !== 'OWNER') return false;
      return true;
    });
    setFilteredUsers(filteredResult);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllUsersApi();
        setUsers(data);
        setFilteredUsers(data);      
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };  
    fetchData();  
  }, [triggerFetch])

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  Usuarios
                </Typography>
              </Stack>
              <div>
                <Button
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Agregar Nuevo Usuario
                </Button>
              </div>
            </Stack>
            <UsersTable
              count={filteredUsers.length}
              items={pagedUsers}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              handleFilters={handleFilters}
            />
          </Stack>
        </Container>
      </Box>

      <FormModal open={openModal}
formType='user'
handleClose={handleCloseModal}
handleSuccess={handleSuccess} />
    </DashboardLayout>
  );
};

export default Users;
