import { Box, Container, Unstable_Grid2 as Grid } from '@mui/material';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '../layouts/dashboard/layout';

const content = [
  {
    title: 'Sobre la plataforma',
    content: 'La plataforma está diseñada para funcionar como panel de administración del sistema de reseñas de Think Data. A través de ella, se pueden editar las empresas, sus sucursales y los usuarios asociados a estas.',
  },
  {
    title: 'Sobre las empresas',
    content: 'En el lado izquierdo se puede acceder a la sección de negocios, donde aparecerán en forma de tabla. Estas pueden clasificarse, y se pueden agregar nuevas empresas desde esta página. Para consultar los detalles de una empresa, simplemente haga clic en su nombre.',
  },
  {
    title: 'Sobre los usuarios',
    content: 'También se pueden consultar los usuarios en el lado izquierdo. Se pueden crear usuarios de tipo administrador (quienes pueden acceder a esta plataforma), de tipo dueño (owner), que funge como único dueño del negocio, y de tipo gerente (manager), que puede tener acceso a la consulta de las reseñas de una o más sucursales. Una vez creados los usuarios, estos pueden editarse al hacer clic en sus nombres en la tabla.',
  },
  {
    title: 'Sobre las sucursales',
    content: 'Las sucursales se crean desde la página de detalles de una empresa, ya que cada sucursal está asignada a una sola empresa. En los detalles de una empresa también se pueden consultar las sucursales asignadas y editarlas al hacer clic en sus nombres.',
  },
]

const Main = () => (
  <DashboardLayout>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
        >
          <Grid
            xs={12}
          >
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h1">
                  Think Data
                </Typography>
                {
                  content.map((section, index) => (
                    <Stack key={index}
                      spacing={1}
                      sx={{ padding: '10px'}}
                    >
                      <Typography variant="h4">
                        {section.title}
                      </Typography>
                      <Typography variant="p">
                        {section.content}
                      </Typography>
                    </Stack>
                  ))
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </DashboardLayout>
);

export default Main;
