import { Route, Routes } from 'react-router-dom';
import Businesses from './Businesses';
import Business from './Business';
import Branch from './Branch';

export const BusinessesRoutes = () => (
  <Routes>
    <Route path='/business/:businessId' element={<Business />} /> 
    <Route path='/branch/:branchId' element={<Branch />} /> 
    <Route path='/*' element={<Businesses/>} />
  </Routes>
)
