import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '../theme';
import { createEmotionCache } from '../utils/create-emotion-cache';
import { Provider } from 'react-redux';
import { store } from '../store';
import 'simplebar-react/dist/simplebar.min.css';
import { AuthProvider } from '../contexts/auth-context';

const clientSideEmotionCache = createEmotionCache();

const AppWrapper = ({ children }) => {
  const theme = createTheme();

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthProvider>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {children}
            </ThemeProvider>
          </Provider>
        </AuthProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default AppWrapper;
