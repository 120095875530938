import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { NewUserForm } from '../../components/forms/new-user-form';
import { deleteUser, getUserApi } from '../../api/users';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../../components/shared/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import ConfirmationModal from '../../components/confirmationModal';
import { useAuth } from '../../hooks/use-auth';

const User = () => {
  const {userId} = useParams();
  const navigate = useNavigate();
  const auth = useAuth();

  const [user, setUser] = useState({});
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleOpenConfirmationModal = () => setConfirmationModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  const handleSuccess = () => {
    ToastSuccessSync("Procedimiento Exitoso");
    setTriggerFetch(prevState => !prevState);
  }

  const handleSuccessDelete = async() => {
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds));  
    }
    handleCloseConfirmationModal();
    try {
      await deleteUser(userId);
      ToastSuccessSync("Procedimiento Exitoso");
      sleep(1000).then(() => {
        navigate(`/users`);
      });
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserApi(userId);
        setUser(data);      
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    userId && fetchData();  
  }, [triggerFetch, userId])

  return (
    <DashboardLayout>
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  {user.name}
                </Typography>
              </Stack>
              {
                String(auth.user.id) !== userId && (
                  <Button
                    startIcon={(
                      <SvgIcon fontSize="small">
                        <TrashIcon />
                      </SvgIcon>
                    )}
                    sx={{ bgcolor: 'error.main', color: 'white' }}
                    variant="contained"
                    onClick={handleOpenConfirmationModal}
                  >
                    Borrar Usuario
                  </Button>
                )
              }
            </Stack>
            <NewUserForm newUser={false}
              user={user}
              handleSuccess={handleSuccess} />
          </Stack>
        </Container>
      </Box>
      <ConfirmationModal open={confirmationModalOpen}
        confirmationText={`¿Estás seguro que deseas borrar al usuario ${user.name}? Toda la información relacionada con este será eliminada sin excepción.`}
        handleClose={handleCloseConfirmationModal}
        handleSuccess={handleSuccessDelete} />
    </DashboardLayout>
  );
};

export default User;
