import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Scrollbar } from '../../components/scrollbar';
import { SeverityPill } from '../../components/severity-pill';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const roles = {
  "ADMIN": "Administrador",
  "MANAGER": "Gerente",
  "OWNER": "Dueño",
};

export const UsersTable = (props) => {
  const navigate = useNavigate();
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    handleFilters
  } = props;

  const [filterOptions, setFilterOptions] = useState({
    active: false,
    inactive: false,
    admin: false,
    manager: false,
    owner: false,
  });

  const { 
    active,
    inactive,
    admin,
    manager,
    owner,
  } = filterOptions;

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      [name]: checked
    }));
  };

  useEffect(() => {
    handleFilters(filterOptions);
    // eslint-disable-next-line
  }, [filterOptions]);

  return (
    <Card>
      <FormControl sx={{ m: 1 }}
        component="fieldset"
        variant="standard">
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Checkbox checked={active} 
                onChange={handleChange} 
                name="active" 
              />
            }
            label="Activo"
          />
          <FormControlLabel
            control={
              <Checkbox checked={inactive} 
                onChange={handleChange} 
                name="inactive" 
              />
            }
            label="Inactivo"
          />
          <FormControlLabel
            control={
              <Checkbox checked={admin} 
                onChange={handleChange} 
                name="admin" 
              />
            }
            label="Administrador"
          />
          <FormControlLabel
            control={
              <Checkbox checked={manager} 
                onChange={handleChange} 
                name="manager" 
              />
            }
            label="Gerente"
          />
          <FormControlLabel
            control={
              <Checkbox checked={owner} 
                onChange={handleChange} 
                name="owner" 
              />
            }
            label="Dueño"
          />
        </FormGroup>
      </FormControl>

      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nombre 
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Empresa
                </TableCell>
                <TableCell>
                  Rol
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Creado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((user) => {
                const createdAt = format(new Date(user.createdAt), 'dd/MM/yyyy');
                return (
                  <TableRow
                    hover
                    key={user.id}
                  >
                    <TableCell onClick={()=>navigate(`/users/user/${user.id}`)}>
                      {user.name}
                    </TableCell>
                    <TableCell>
                      {user.email}
                    </TableCell>
                    <TableCell>
                      {user.business[0]?.business.name}
                    </TableCell>
                    <TableCell>
                      {roles[user.role]}
                    </TableCell>
                    <TableCell>
                      <SeverityPill color={user.status ? 'success' : 'error'}>
                        {user.status ? 'Activo' : 'Inactivo'}
                      </SeverityPill>
                    </TableCell>
                    <TableCell>
                      {createdAt}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

UsersTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};
