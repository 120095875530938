import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  InputLabel,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getUsersByBusinessApi } from '../../api/users';
import { patchBusinessApi, postBusinessApi } from '../../api/businesses';

export const NewBusinessForm = ({newBusiness = true, business, handleSuccess }) => {
  const [modified, setModified] = useState(false);
  const [users, setUsers] = useState([]);
    
  const formik = useFormik({
    initialValues: {
      name: '',
      stripeCustomerId: '',
      stripeStatus: '',
      description: '',
      adminId: '',
      status: false,
      submit: null
    },
    validationSchema: Yup.object({
      name: Yup
        .string()
        .max(255)
        .required('Inserta Nombre'),
      stripeCustomerId: Yup
        .string()
        .max(255)
        .required('Inserta usuario de Stripe'),
      stripeStatus: Yup
        .string()
        .max(255)
        .required('Inserta estado del usuario de Stripe'),
      description: Yup
        .string()
        .max(255)
        .required('Inserta Descripción'),
      adminId: Yup
        .number()
        .positive()
        .integer(),
      status: Yup
        .boolean(),
    }),
    onSubmit: async (values, helpers) => {
      const dataToPost = {...values};
      newBusiness ? console.log('newBusiness', dataToPost) : console.log('modifiedBusiness', dataToPost);
      try {
        if (!dataToPost.adminId) delete dataToPost.adminId;
        newBusiness ? await postBusinessApi(dataToPost) : await patchBusinessApi(business.id, dataToPost);
        helpers.resetForm();
        setModified(false);
        handleSuccess();      
      } catch (error) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleChange = () => {
    setModified(true);
  } 

  useEffect(() => {
    if(!newBusiness){
      formik.setValues({
        name: business.name ? business.name : '',
        stripeCustomerId: business.stripeCustomerId ? business.stripeCustomerId : '',
        stripeStatus: business.stripeStatus ? business.stripeStatus : '',
        description: business.description ? business.description : '',
        adminId: business.adminId ? business.adminId : '',
        status: business.status ? business.status : false,
        submit: null
      }); 
    }
    // eslint-disable-next-line
  }, [business, newBusiness])
  
  useEffect(() => {
    const fetchBusinessUsers = async () => {
      if (business?.id) {
        try {
          const data = await getUsersByBusinessApi(business.id);
          setUsers(data);      
        } catch (error) {
          console.error('Error fetching business users:', error);
        }
      }
    };  
    fetchBusinessUsers();  
  }, [business])

  return (
    <>
    <form
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader
          subheader={ newBusiness ? 'Agrega un negocio nuevo' : 'Modifica tu negocio' }
          title={ newBusiness ? 'Nuevo Negocio' : '' }
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Nombre del negocio"
                  name="name"
                  error={!!(formik.touched.name && formik.errors.name)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.name}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Usuario de Stripe"
                  name="stripeCustomerId"
                  error={!!(formik.touched.stripeCustomerId && formik.errors.stripeCustomerId)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.stripeCustomerId}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Estado de usuario de Stripe"
                  name="stripeStatus"
                  error={!!(formik.touched.stripeStatus && formik.errors.stripeStatus)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.stripeStatus}
                />
              </Grid>
              { !newBusiness &&
                <Grid
                xs={12}
                md={6}
                >
                  <InputLabel>Administrador del Negocio</InputLabel>
                  <Select
                    label="Administrador del Negocio"
                    fullWidth
                    name="adminId"
                    error={!!(formik.touched.adminId && formik.errors.adminId)}
                    onChange={(e) => {formik.handleChange(e);handleChange();}}
                    value={formik.values.adminId}
                    >
                    {users.filter(user => user.role === 'OWNER').map((option) => (
                      <MenuItem key={option.id}
value={option.id}>{option.name}</MenuItem>
                      ))}
                  </Select>
                </Grid>
              }
              { !newBusiness &&
                <Grid
                xs={12}
                md={6}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.status}
                        value={formik.values.status}
                        onChange={(e) => {formik.handleChange(e);handleChange();}}
                        name='status'
                        onBlur={formik.handleBlur}      
                    />
                    }
                    label="Activa"
                  />
                </Grid>
              }
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Descripción"
                  name="description"
                  minRows={3}
                  multiline
                  error={!!(formik.touched.description && formik.errors.description)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.description}
                />
              </Grid>
            </Grid>
            {formik.errors.submit && (
              <Typography
                color="error"
                sx={{ mt: 3 }}
                variant="body2"
              >
                {formik.errors.submit}
              </Typography>
            )}

          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          { newBusiness &&
            <Button variant="contained"
type="submit">
              Crear Negocio
            </Button>
          }
          { !newBusiness && modified &&
            <Button variant="contained"
type="submit">
              Modificar Negocio
            </Button>
          }
        </CardActions>
      </Card>
    </form>
    </>
  );
};
