import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import { NewBusinessForm } from '../../components/forms/new-business-form';
import { BranchesTable } from '../../components/tables/branches-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { applyPagination } from '../../utils/apply-pagination';
import FormModal from '../../components/formModal';
import { deleteBusiness, getBusinessApi } from '../../api/businesses';
import { getBranchesByBusinessApi } from '../../api/branches';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../../components/shared/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationModal from '../../components/confirmationModal';

const Business = () => {
  const {businessId} = useParams();
  const navigate = useNavigate();
  const usePagedBranches = (page, rowsPerPage) => {
    return useMemo(
      () => {
        return applyPagination(filteredBranches, page, rowsPerPage);
      },
      // eslint-disable-next-line
      [page, rowsPerPage, filteredBranches]
    );
  };

  const [business, setBusiness] = useState({});
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const pagedBranches = usePagedBranches(page, rowsPerPage);
  
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleOpenConfirmationModal = () => setConfirmationModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  const handleSuccess = () => {
    handleCloseModal();
    ToastSuccessSync("Procedimiento Exitoso");
    setTriggerFetch(prevState => !prevState);
  }
  
  const handlePageChange = useCallback(
    (_, value) => {
      setPage(value);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  );

  const handleFilters = (filters) => {
    const filteredResult = branches.filter(branch => {
      if (filters.active && !branch.status) return false;
      if (filters.inactive && branch.status) return false;
      return true;
    });
    setFilteredBranches(filteredResult);
  };
      
  useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await getBranchesByBusinessApi(businessId);
            console.log(data)
            setBranches(data);      
            setFilteredBranches(data);      
        } catch (error) {
            console.error('Error fetching branches:', error);
        }
    };
    const fetchBusiness = async () => {
        try {
            const data = await getBusinessApi(businessId);
            console.log(data)
            setBusiness(data);      
        } catch (error) {
            console.error('Error fetching business:', error);
        }
    };
    businessId && fetchData();  
    businessId && fetchBusiness();  
  }, [triggerFetch, businessId])

  const handleSuccessDelete = async() => {
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds));  
    }
    handleCloseConfirmationModal();
    try {
      await deleteBusiness(businessId);
      ToastSuccessSync("Procedimiento Exitoso");
      sleep(2000).then(() => {
        navigate('/businesses');
      });
    } catch (error) {
      console.error('Error deleting business:', error);
    }
  }

  return (
    <DashboardLayout>
      <ToastContainer />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  {business.name}
                </Typography>
              </Stack>
              <div>
                <Button
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <TrashIcon />
                    </SvgIcon>
                  )}
                  sx={{ bgcolor: 'error.main', color: 'white' }}
                  variant="contained"
                  onClick={handleOpenConfirmationModal}
                >
                  Borrar Negocio
                </Button>
              </div>
            </Stack>
            <NewBusinessForm newBusiness={false}
              business={business}
              handleSuccess={handleSuccess} />
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  Sucursales
                </Typography>
              </Stack>
              <div>
                <Button
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Agregar Nueva Sucursal
                </Button>
              </div>
            </Stack>
            <BranchesTable
              count={branches.length}
              items={pagedBranches}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              handleFilters={handleFilters}
            />
          </Stack>
        </Container>
      </Box>

      <FormModal open={openModal}
        formType='branch'
        businessId={businessId}
        handleClose={handleCloseModal}
        handleSuccess={handleSuccess} />
      
      <ConfirmationModal open={confirmationModalOpen}
        confirmationText={`¿Estás seguro que deseas borrar el negocio ${business.name}? Toda la información relacionada con este negocio será eliminada sin excepción.`}
        handleClose={handleCloseConfirmationModal}
        handleSuccess={handleSuccessDelete} />
    </DashboardLayout>
  );
};

export default Business;
