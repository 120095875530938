import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/auth-context';

export const PublicRoute = ({children}) => {
  const {isAuthenticated} = useAuthContext();

  return (!isAuthenticated) ?
    children
    : <Navigate to='/' />
}
