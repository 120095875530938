import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { patchBranchApi, postBranchApi } from '../../api/branches';
import { getGroupsCollections } from '../../api/groupsCollections';

export const NewBranchForm = ({newBranch = true, businessId, branch, handleSuccess }) => {
  const [modified, setModified] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  const [groupsCollections, setGroupsCollections] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      groups: '',
      status: false,
      submit: null
    },
    validationSchema: Yup.object({
      name: Yup
        .string()
        .max(255)
        .required('Inserta Nombre'),
      address: Yup
        .string()
        .max(255)
        .required('Inserta Dirección'),
      groups: Yup
        .string()
        .max(255)
        .required('Selecciona un grupo'),
      status: Yup
        .boolean(),
    }),
    onSubmit: async (values, helpers) => {
      const businessIdAsNumber = Number(businessId);
      const dataToPost = {
        ...values, 
        businessId: businessIdAsNumber,
        groups: currentGroup.groups 
      };
      newBranch ? console.log('newBranch', dataToPost) : console.log('modifiedBranch', dataToPost);
      try {
        if (!dataToPost.adminId) delete dataToPost.adminId;
        newBranch ? await postBranchApi(dataToPost) : await patchBranchApi(branch.id, dataToPost);
        setModified(false);
        handleSuccess();      
        helpers.setTouched( false )
        helpers.setErrors( false )
      } catch (error) {
        helpers.setStatus( false );
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },      
  });

  useEffect(() => {
    if(!newBranch){
      formik.setValues({
        name: branch.name ? branch.name : '',
        address: branch.address ? branch.address : '',
        status: branch.status ? branch.status : false,
        groups: '',
        submit: null
      }); 
    }
    // eslint-disable-next-line
  }, [branch])

  const handleGroupsCollectionsChange = async (e, values) => {
    const groupName = values.props.value;
    const groupsCollectionObj = groupsCollections.find(groupsCollection => groupsCollection.name === groupName);
    setCurrentGroup(groupsCollectionObj);
    setModified(true);
  };

  useEffect(() => {
    const fetchGroupsCollections = async () => {
      try {
        const data = await getGroupsCollections();
        setGroupsCollections(data);      
      } catch (error) {
        console.error('Error fetching parentReview:', error);
      }
    };  
    fetchGroupsCollections();
  }, [])

  const handleChange = () => {
    setModified(true);
  } 

  return (
    <form
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader
          subheader={ newBranch ? "Agrega una sucursal nueva" : "Modifica tu Sucursal"}
          title={ newBranch ? "Nueva Sucursal" : ""}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Nombre de la sucursal"
                  name="name"
                  error={!!(formik.touched.name && formik.errors.name)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.name}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Dirección"
                  name="address"
                  minRows={3}
                  multiline
                  error={!!(formik.touched.address && formik.errors.address)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  value={formik.values.address}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <InputLabel>Colección</InputLabel>
                <Select
                  name="groups"
                  error={!!(formik.touched.groups && formik.errors.groups)}                  
                  fullWidth
                  value={currentGroup.name || ''}
                  onChange={(e, values) => {formik.handleChange(e);handleGroupsCollectionsChange(e, values);}}
                >
                  {
                    groupsCollections.map((groupsCollection) => (
                      <MenuItem
                        key={groupsCollection.id}
                        value={groupsCollection.name}
                      >
                        {groupsCollection.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Grid>
              { !newBranch &&
                <Grid
                xs={12}
                md={6}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.status}
                        value={formik.values.status}
                        onChange={(e) => {formik.handleChange(e);handleChange();}}
                        name='status'
                        onBlur={formik.handleBlur}      
                    />
                    }
                    label="Activa"
                  />
                </Grid>
              }
            </Grid>
            {formik.errors.submit && (
              <Typography
                color="error"
                sx={{ mt: 3 }}
                variant="body2"
              >
                {formik.errors.submit}
              </Typography>
            )}

          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          { newBranch &&
            <Button variant="contained"
type="submit">
              Crear Sucursal
            </Button>
          }
          { !newBranch && modified &&
            <Button variant="contained"
type="submit">
              Modificar Sucursal
            </Button>
          }
        </CardActions>
      </Card>
    </form>
  );
};
