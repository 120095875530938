import { Route, Routes } from 'react-router-dom';
import Users from './Users';
import User from './User';

export const UsersRoutes = () => (
  <Routes>
    <Route path='user/:userId' element={<User />} /> 
    <Route path='/*' element={<Users/>} />
  </Routes>
)
