import { useEffect, useState } from 'react';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getGroupsCollections, patchGroupsCollectionByIdApi, postGroupsCollectionApi } from '../../api/groupsCollections';

export const LllmGroupsForm = ({newCollection = true , handleSuccess}) => {
  const [modified, setModified] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  const [groups, setGroups] = useState([]);
  const [groupsCollections, setGroupsCollections] = useState([]);

  const formik = useFormik({
    initialValues: {
      group: '',
      submit: null
    },
    validationSchema: Yup.object({
      group: Yup
        .string()
        .max(60)
        .required('Inserta Nombre'),
    }),
    onSubmit: async (values, helpers) => {
      addGroup(values.group);
      helpers.resetForm();
    },
  });
  
  const formikSubmit = useFormik({
    initialValues: {
      name: '',
      submit: null
    },
    validationSchema: Yup.object({
      name: Yup
        .string()
        .max(60)
        .required('Inserta Nombre'),
    }),
  });

  const addGroup = (group) => {
    if (!groups.includes(group)){
      setModified(true);
      setGroups(sortAlphabetically([...groups, group]));
    }
  }
  
  const deleteGroup = (groupToDelete) => {
    setModified(true);
    setGroups(sortAlphabetically(groups.filter(group => group !== groupToDelete)));
  }
  
  const handleGroupsCollectionsChange = async (e, values) => {
    const groupName = values.props.value;
    const groupsCollectionObj = groupsCollections.find(groupsCollection => groupsCollection.name === groupName);
    setCurrentGroup(groupsCollectionObj);
    const groups = groupsCollectionObj.groups;
    const groupsAsList = [...JSON.parse(groups)];
    setGroups(sortAlphabetically(groupsAsList));
    setModified(true);
  };

  const sortAlphabetically = (unsortedGroups) => {
    unsortedGroups.sort((a, b) => {
      let nameA = a.toUpperCase();
      let nameB = b.toUpperCase();
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
    });  
    return unsortedGroups;
  }

  const sortGroupsCollectionsList = (unsortedGroupsCollections) => {
    unsortedGroupsCollections.sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
    });  
    return unsortedGroupsCollections;
  }
  
  const updateGroups = async () => {
    const newCollectionName = formikSubmit.values.name;
    if(groups.length < 3) return;
    if(groupsCollections.find(gC=>gC.name === newCollectionName)) return;
    if(newCollection){
        if(formikSubmit.errors.name || !newCollectionName){
            formikSubmit.setFieldError("name", "Empty");
            formikSubmit.setTouched({name: true});
            return;
        }
        try {
            await postGroupsCollectionApi({
                name: newCollectionName,
                groups: JSON.stringify(groups)
            });
            handleSuccess();
            setTriggerFetch(!triggerFetch);
        } catch (error) {
            console.error(error);
        }
    } else {
        try {
            await patchGroupsCollectionByIdApi(
                currentGroup.id, 
                {groups: JSON.stringify(groups)}
            );
            handleSuccess();
            setTriggerFetch(!triggerFetch);
            setModified(false);
        } catch (error) {
            console.error('Error updating groups', error);
        }      
    }
  }

  useEffect(() => {
    const fetchGroupsCollections = async () => {
      try {
        const data = await getGroupsCollections();
        setGroupsCollections(sortGroupsCollectionsList(data));      
      } catch (error) {
        console.error('Error fetching parentReview:', error);
      }
    };  
    fetchGroupsCollections();
  }, [triggerFetch])
  
  return (
    <form
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader
          subheader="Grupos a través de los cuales se clasifican las reseñas"
          title="Grupos Temáticos"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box>
            <Grid
              container
              spacing={3}
            >
                {
                newCollection &&
                <Grid
                  xs={6}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}                  
                  >
                    <TextField
                      fullWidth
                      label="Nombre de la Colección"
                      name="name"
                      error={!!(formikSubmit.touched.name && formikSubmit.errors.name)}
                      onBlur={formikSubmit.handleBlur}
                      onChange={formikSubmit.handleChange}
                      value={formikSubmit.values.name}
                    />
                  </Stack>
                  {
                    (groupsCollections.find(gC=>gC.name === formikSubmit.values.name)) && (
                      <Typography
                      color="error"
                      sx={{ mt: 3 }}
                      variant="body2"
                      >
                        Ya existe una clasificación con nombre {formikSubmit.values.name}
                      </Typography>
                    )
                }
                </Grid>
              }
              <Stack
                sx={{
                  width: '50%'
                }}                
              >
                <InputLabel>Colección</InputLabel>
                <Select
                  name="users"
                  fullWidth
                  value={currentGroup.name || ''}
                  onChange={handleGroupsCollectionsChange}
                >
                  {
                    groupsCollections.map((groupsCollection) => (
                      <MenuItem
                        key={groupsCollection.id}
                        value={groupsCollection.name}
                      >
                        {groupsCollection.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Stack>
              <Stack
                useFlexGap 
                flexWrap="wrap"
                direction="row"
                alignItems="center"
                spacing={3}
                sx={{
                  padding: '15px',
                  width: '100%'
                }}
              >
                {
                  groups.map((group, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Button 
                        onClick={()=>deleteGroup(group)}
                        variant="contained" 
                        color='error'
                        size="small" 
                      >
                        {group}
                        <SvgIcon sx={{marginLeft : '8px'}}>
                          <XMarkIcon />
                        </SvgIcon>
                      </Button>
                    </Stack>
                  ))
                }
              </Stack>
              {
              (groups.length < 3) && modified && (
                <Typography
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                    Al menos se necesitan 3 grupos
                </Typography>
              )
              }
              <Grid
                xs={6}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}                  
                >
                  <TextField
                    fullWidth
                    label="Nombre del grupo"
                    name="group"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.group}
                  />
                  <Button
                    variant='contained'
                    size='small'
                    type='submit'>
                      Agregar Grupo
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            {formik.errors.submit && (
              <Typography
                color="error"
                sx={{ mt: 3 }}
                variant="body2"
              >
                {formik.errors.submit}
              </Typography>
            )}

          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          { modified && !newCollection &&
            <Button variant="contained"
              onClick={updateGroups}>
              Modificar grupos de Colección
            </Button>
          }
          { newCollection &&
            <Button variant="contained"
              onClick={updateGroups}>
              Agregar Colección
            </Button>
          }
        </CardActions>
      </Card>
    </form>
  );
};
